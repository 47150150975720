import { Container, Row, Col } from "react-bootstrap";
import linkedIn from "../assets/img/linkedIn.svg";
import email from "../assets/img/email.svg";

export const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <Container>
        <Row className="align-items-center">
          <Col size={12} sm={12} className="text-center text-sm-end">
            <div className="social-icon">
              <a href="#"><img src={linkedIn} alt="Icon" /></a>
              <a href="#"><img src={email} alt="Icon" /></a>
            </div>
            <p>Copyright@{currentYear}. All Rights Reserved to Al-Hamd Tex</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
