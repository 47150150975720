import whiteRags from "../assets/img/whiteRags.png";
import coloredTowel from "../assets/img/coloredTowel.png";
import whiteTowel from "../assets/img/whiteTowel.png";
import weldingGloves from "../assets/img/weldingGloves.png";
import yellowDuster from "../assets/img/yellowDuster.png";
import kitchenTowel from "../assets/img/kitchenTowel.png";
import brownIndustrialGloves from "../assets/img/Brown Industrial Gloves.png";
import redIndustrialGloves from "../assets/img/Red Industrial Gloves.png";
import yellowIndustrialGloves from "../assets/img/Yellow Industrial Gloves.png";
import leatherIndustrialGLoves from "../assets/img/Leather Industrial Gloves.png";
import longElbowGLoves from "../assets/img/Long Elbow Gloves.png";
import whiteDottedGloves from "../assets/img/White Dotted Gloves.png";
import ductingCloth5555 from "../assets/img/DuctingCloth5555.png";
import ductingCloth7777 from "../assets/img/DuctingCloth7777.png";
import ductingCloth9999 from "../assets/img/DuctingCloth9999.png";
import ductingClothWhiteComparision7777 from "../assets/img/WhiteDuctingClothComparison7777.png";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"


export const Products = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="product" id="products">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="product-bx wow zoomIn">
              <h2>Our Products</h2>
              <p>Elevating Everyday Essentials<br></br> Explore our thoughtfully crafted products<br></br> We also manufacture custom made products</p>
              <Carousel responsive={responsive} infinite={true}>
                <div className="item">
                  <img src={coloredTowel} alt="Bath Linen" />
                  <h3>Bath Linen</h3>
                </div>
                <div className="item">
                  <img src={yellowIndustrialGloves} alt="Yellow Industrial Gloves" />
                  <h3>Yellow Working Gloves</h3>
                </div>
                <div className="item">
                  <img src={brownIndustrialGloves} alt="Yellow Industrial Gloves" />
                  <h3>Brown Working Gloves</h3>
                </div>
                <div className="item">
                  <img src={redIndustrialGloves} alt="Yellow Industrial Gloves" />
                  <h3>Red Working Gloves</h3>
                </div>
                <div className="item">
                  <img src={leatherIndustrialGLoves} alt="Yellow Industrial Gloves" />
                  <h3>Leather Working Gloves</h3>
                </div>
                <div className="item">
                  <img src={longElbowGLoves} alt="Yellow Industrial Gloves" />
                  <h3>Welding Gloves</h3>
                </div>
                <div className="item">
                  <img src={whiteDottedGloves} alt="White Dotted Gloves" />
                  <h3>Dotted Cotton Gloves</h3>
                </div>
                <div className="item">
                  <img src={kitchenTowel} alt="Kitchen Help" />
                  <h3>Kitchen Help</h3>
                </div>
                <div className="item">
                  <img src={whiteTowel} alt="Hospitality Institutional" />
                  <h3>Hospitality Institutional</h3>
                </div>
                <div className="item">
                  <img src={yellowDuster} alt="Cleaning Supplies" />
                  <h3>Cleaning Supplies</h3>
                </div><div className="item">
                  <img src={weldingGloves} alt="Industrial Support" />
                  <h3>Industrial Support</h3>
                </div><div className="item">
                  <img src={coloredTowel} alt="Colored Rags" />
                  <h3>Colored Rags</h3>
                </div><div className="item">
                  <img src={whiteRags} alt="White Rags" />
                  <h3>White Rags</h3>
                </div>
                <div className="item">
                  <img src={ductingCloth5555} alt="White Dotted Gloves" />
                  <h3>Ducting Cloth #5555</h3>
                </div>
                <div className="item">
                  <img src={ductingCloth7777} alt="White Dotted Gloves" />
                  <h3>Ducting Cloth #7777</h3>
                </div><div className="item">
                  <img src={ductingCloth9999} alt="White Dotted Gloves" />
                  <h3>Ducting Cloth #9999</h3>
                </div>
                <div className="item">
                  <img src={ductingClothWhiteComparision7777} alt="White Dotted Gloves" />
                  <h3>Comparison of White Ducting Cloth #7777</h3>
                </div>
              </Carousel>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  )
}
