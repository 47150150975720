import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ServiceCard } from "./ServiceCard";
import projImg1 from "../assets/img/bale.png";
import projImg2 from "../assets/img/bale2.png";
// import projImg3 from "../assets/img/bale3.mp4";
import projImg4 from "../assets/img/bale4.jpg";
import projImg5 from "../assets/img/bale5.jpg";
import projImg6 from "../assets/img/bale6.jpg";
import projImg7 from "../assets/img/bale7.jpg";
import projImg8 from "../assets/img/bale8.jpg";
import projImg9 from "../assets/img/bale9.jpg";
import press from "../assets/img/press.jpg";
import press1 from "../assets/img/press1.jpg";
import industrialGloves from "../assets/img/IndustrialGloves.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import TrackVisibility from 'react-on-screen';

export const Services = () => {

  const manufactured = [
    {
      title: "Working GLoves",
      description: "Industrial gloves used for welding and other purposes",
      imgUrl: industrialGloves,
    },
    // {
    //   title: "Bath Towel",
    //   description: "Combed yarn towel being manufactured",
    //   imgUrl: projImg2,
    // },
    // {
    //   title: "Shop Towel",
    //   description: "Tubular manufactured at our unit",
    //   imgUrl: projImg1,
    // },

  ];

  const recycled = [
    // {
    //   title: "Colored Rags",
    //   description: "Bales of 100 kg bales",
    //   imgUrl: projImg1,
    // },
    {
      title: "Hydraulic Press",
      description: "Presses bales of 100 kg",
      imgUrl: press,
    },
    {
      title: "Hydraulic Press",
      description: "Presses bales of 100 kg",
      imgUrl: press1,
    },
    {
      title: "White Rags",
      description: "Bales of 100 kg bales",
      imgUrl: projImg2,
    },
    {
      title: "Rags",
      description: "Bales stacked",
      imgUrl: projImg5,
    },
    {
      title: "Rags",
      description: "Bales stacked",
      imgUrl: projImg6,
    },
    {
      title: "Rags",
      description: "Bales stacked",
      imgUrl: projImg7,
    },
    {
      title: "Rags",
      description: "Bales stacked",
      imgUrl: projImg8,
    },
    {
      title: "Rags",
      description: "Bales stacked",
      imgUrl: projImg9,
    },
    {
      title: "White Rags",
      description: "Bales of 100 kg bales",
      imgUrl: projImg4,
    },
  ];

  return (
    <section className="service" id="services">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                  {/* <h2>What we do?</h2> */}
                  {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p> */}
                  <Tab.Container id="services-tabs" defaultActiveKey="manufactured">
                    <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                      <Nav.Item>
                        <Nav.Link eventKey="manufactured">Manufactured</Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item>
                      <Nav.Link eventKey="second">Tab 2</Nav.Link>
                    </Nav.Item> */}
                      <Nav.Item>
                        <Nav.Link eventKey="recycled">Recycled</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content id="slideInUp" className={isVisible ? "animate__animated animate__slideInUp" : ""}>
                      <Tab.Pane eventKey="manufactured">
                        <Row>
                          {
                            manufactured.map((service, index) => {
                              return (
                                <ServiceCard
                                  key={index}
                                  {...service}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="recycled">
                        <Row>
                          {
                            recycled.map((service, index) => {
                              return (
                                <ServiceCard
                                  key={index}
                                  {...service}
                                />
                              )
                            })
                          }
                        </Row>
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="third">
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque quam, quod neque provident velit, rem explicabo excepturi id illo molestiae blanditiis, eligendi dicta officiis asperiores delectus quasi inventore debitis quo.</p>
                      </Tab.Pane> */}
                    </Tab.Content>
                  </Tab.Container>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
